.container {
  max-width: 1200px;
  margin-top: 10px;
}

.headings {
  font-family: sans-serif Arial, Helvetica, sans-serif;
}

.signIn {
  margin-top: 1rem;
  color: red !important;
}

.tablehead {
  color: white !important;
  background-color: #007bff !important;
}

.btnRight {
  padding-left: 41rem;
}
