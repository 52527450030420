body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Arial', 'sans-serif', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sign-in {
  border-radius: 4px;
  position: relative;
  display: block;
  border: 1px #ddd solid;
  background-color: #fff;
  padding: 20px 26px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.heading {
  font-family: Arial, Helvetica, sans-serif;
}